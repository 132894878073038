body {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 4;
  pointer-events: none;
  animation: flicker 0.15s infinite;
}

.crt::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
  z-index: 6;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
  transform: rotateX(2deg) rotateY(-2deg);
  /* Garante que o filtro CRT acompanhe a curvatura */
  transform-origin: center;
}

.crt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: textShadow 10s infinite;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  /* Permite cliques nos elementos abaixo */
}

.crt::before,
.crt::after {
  pointer-events: none;
  /* Garante que o overlay não bloqueie os cliques */
}

.mulher {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  z-index: 5;
  transform: translate(-50%, -50%);
}

@keyframes flicker {
  0% {
    opacity: 0.27861;
  }

  5% {
    opacity: 0.34769;
  }

  10% {
    opacity: 0.23604;
  }

  15% {
    opacity: 0.90626;
  }

  20% {
    opacity: 0.18128;
  }

  25% {
    opacity: 0.83891;
  }

  30% {
    opacity: 0.65583;
  }

  35% {
    opacity: 0.67807;
  }

  40% {
    opacity: 0.26559;
  }

  45% {
    opacity: 0.84693;
  }

  50% {
    opacity: 0.96019;
  }

  55% {
    opacity: 0.08594;
  }

  60% {
    opacity: 0.20313;
  }

  65% {
    opacity: 0.71988;
  }

  70% {
    opacity: 0.53455;
  }

  75% {
    opacity: 0.37288;
  }

  80% {
    opacity: 0.71428;
  }

  85% {
    opacity: 0.70419;
  }

  90% {
    opacity: 0.7003;
  }

  95% {
    opacity: 0.36108;
  }

  100% {
    opacity: 0.24387;
  }
}

.Background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("./BACKMONEY/1.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  z-index: 0;
}

.Page {
  width: 100vw;
  height: 10vh;
  position: absolute;
  z-index: 6;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

a {
  text-decoration: none;
  color: white;
}

.links {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 40px;
}

.buy {
  width: 180px;
  height: 80px;
  background-color: #4ade80;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  animation: moveBuy linear infinite 2s;
}

.buy:hover {
  background-color: #bbf7d0;
}

@keyframes moveBuy {
  0% {
    transform: scale(1) rotate(0deg);
  }

  25% {
    transform: scale(1.1) rotate(2deg);
  }

  50% {
    transform: scale(1) rotate(0deg);
  }

  75% {
    transform: scale(1.1) rotate(-2deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}

.social {
  margin-left: 40px;
}

.social {
  font-size: 34px;
  transition: font-size 0.3s ease;
  /* Transição suave */
}

.social:hover {
  font-size: 40px;
  /* Aumenta apenas o tamanho da fonte no hover */
}

.tokenCa {
  color: white;
  font-size: 22px;
  background-color: rgba(0, 0, 0, 0.01);
  height: 45px;
  width: 680px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  position: relative;
}

.moneyEmoji {
  opacity: 0;
  /* Oculto por padrão */
  transition: opacity 0.3s ease;
  /* Transição suave */
}

.tokenCa:hover .moneyEmoji {
  opacity: 1;
  /* Visível ao passar o mouse */
}

.envCa {
  margin-right: 40px;
  height: 80px;
  display: flex;
  align-items: center;
}

.img {
  position: absolute;
  bottom: 20px;
  z-index: 3;
}

.l {
  left: 0;
  width: 300px;
  animation: leftrun linear infinite 4s;
}

.r {
  right: 20px;
  animation: rightrun linear infinite 4s;
}


@keyframes leftrun {
  0%{
    left: 0;
  }
  50%{
    left: 100%;
  }
  100%{
    left: 0;
  }
}

@keyframes rightrun {
  0%{
    right: 0;
  }
  50%{
    right: 100%;
  }
  100%{
    right: 0;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: white;
}

#volumeControl {
  width: 200px;
  margin: 10px 0;
}

.button {
  padding: 10px 20px;
  background-color: #4ade80;
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #3ac16e;
}

.controls {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 80%;
}

.music-button {
  font-size: 75px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}


.music-controls {
  width: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.music-label{
  font-size: 28px;
}

.volume-knob {
  -webkit-appearance: none;
  appearance: none;
  width: 200px;
  height: 8px;
  background-color: #d1d1d1;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); 
}

.volume-knob::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

/* Estilo da barra ao ser ajustada */
.volume-knob:active {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Estilo do thumb (botão do controle deslizante) */
.volume-knob::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px; /* Largura do thumb */
  height: 18px; /* Altura do thumb */
  background-color: #ffffff; /* Cor do thumb */
  border: 2px solid rgba(0, 0, 0, 0.8); /* Borda do thumb */
  border-radius: 50%; /* Formato redondo */
  cursor: pointer; /* Cursor para interação */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra do thumb */
  transition: transform 0.1s ease;
  margin-top: -5px;
}

/* Efeito de clique no thumb */
.volume-knob::-webkit-slider-thumb:active {
  transform: scale(1.8); /* Efeito de aumento ao clicar */
}

/* Estilos para navegadores Firefox */
.volume-knob::-moz-range-track {
  width: 100%;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

.volume-knob::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9);
}

.volume-knob::-ms-thumb {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}




.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit; /* Herdando o background do body */
  opacity: 0.6;
  z-index: -1; /* Colocando atrás do conteúdo */
  pointer-events: none;
}

.moneyEmoji{
  font-size: 45px;
}

@font-face {
  font-family: 'Money';
  src: url('./Money.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.env-tokenName{
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 20%;
  left: 50%;
  z-index: 10000;

}

.tokenName{
  font-size: 150px;
  font-family:'Money';
}

.text{
  text-align: center;
  font-size: 22px;
  margin-top: -20px;
  font-weight: bold;
}