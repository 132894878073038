.animation-container {
    position: relative;
    width: 600px;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .animation-wrapper {
    position: absolute;
    z-index: 9;
    top: 60%;
    left: 25%;
    transform: translate(-50%, -50%);
  }
  
  .animated-image {
    position: absolute;
    width: 25vh;
    height: 25vh;
    opacity: 0;
    animation: floatAndFade 5s ease-in-out infinite;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  }
  
  .img1 {
    animation-delay: 0s;
  }
  
  .img2 {
    animation-delay: 1.5s;
  }
  
  .img3 {
    animation-delay: 3s;
  }
  
  @keyframes floatAndFade {
    0% {
      opacity: 0;
      transform: translate(0, 0) scale(0.8);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    10% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
      transform: translate(-100px, -100px) scale(1);
      box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
    }
    70% {
      opacity: 0.5;
      transform: translate(-150px, -150px) scale(1.1);
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    }
    80% {
      opacity: 0.3;
    }
    100% {
      opacity: 0;
      transform: translate(-200px, -200px) scale(1.2);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }
  }
  